import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';
import { isProd } from '@agria/utils';

export const ScriptsBefore = () => (
  <>
    {/* // OneTrust Cookies Consent Notice */}
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      src="https://cdn.cookielaw.org/consent/f9a29e4f-48b7-41c7-ae5b-fa18f599e9b6/OtAutoBlock.js"
    />
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      data-lang="en"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      charSet="UTF-8"
      data-domain-script={`f9a29e4f-48b7-41c7-ae5b-fa18f599e9b6${
        !isProd ? '-test' : ''
      }`}
    />
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="text/javascript"
      dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
    />
    {/* GTM */}
    <Script strategy={ScriptStrategy.postHydrate}>
      {`
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://analytics.agriapetinsure.ie/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NWRMBJN');
      `}
    </Script>
    <noscript>
      <iframe
        src="https://analytics.agriapetinsure.ie/gtm.js?id=GTM-NWRMBJN"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        title="gtm-frame"
      />
    </noscript>
  </>
);
