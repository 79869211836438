import React from 'react';

export const Logo = () => (
  <svg
    width={126}
    height={65}
    viewBox="0 0 126 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3632_6509)">
      <path
        d="M38.7196 16.509C32.1681 16.3716 27.8196 20.8619 27.8196 27.8213C27.8196 34.7806 31.2106 39.4571 36.3392 39.4571C37.8951 39.4571 39.6815 39.0449 40.967 38.083L41.2418 37.8791C41.6097 40.4722 39.0742 42.3517 36.7559 42.3517C34.6725 42.3517 32.7487 41.6291 31.0599 40.4811L28.8125 44.7365C31.5874 46.7445 34.677 47.436 37.1681 47.436C40.9714 47.436 46.8359 45.7427 46.8359 36.6246V18.5259C44.2693 17.2892 41.5211 16.509 38.7285 16.509H38.7196ZM33.8658 27.8213C33.8658 24.2042 35.6078 21.7751 38.0813 21.7751C38.9989 21.7751 39.9607 21.9125 40.9226 22.2316V32.8125C39.779 33.7744 38.5866 33.9561 37.7621 33.9561C35.3818 33.9561 33.8702 31.8018 33.8702 27.8168L33.8658 27.8213Z"
        fill="#005AA0"
      />
      <path
        d="M19.2822 33.7699H8.72355L6.79533 39.0981H0V38.1805L12.3938 6.63739H15.6519L28.0014 38.1805V39.0981H21.2504L19.2778 33.7699H19.2822ZM12.1678 23.9249L10.7404 28.3709H17.221L15.7494 23.8762L14.0073 18.1137L12.1722 23.9249H12.1678Z"
        fill="#005AA0"
      />
      <path
        d="M62.3991 16.5091C63.8219 16.4648 65.5197 17.1075 66.6677 18.1625L64.9213 23.1227C64.1411 22.5243 62.8556 22.112 61.6632 22.112C59.3671 22.112 57.3945 23.7654 57.3945 27.4402V39.1026H51.4724V23.5793C51.4724 21.7441 50.9671 20.5473 49.3137 20.8709V16.9214C51.9778 16.1855 56.8892 16.0969 57.2084 20.042C58.1703 17.7458 60.1472 16.5091 62.3946 16.5091H62.3991Z"
        fill="#005AA0"
      />
      <path
        d="M71.597 6.5C73.3391 6.5 75.0855 7.78548 75.0855 10.1747C75.0855 12.5639 73.3878 13.8494 71.6413 13.8937C69.8505 13.9381 68.0154 12.7013 68.0154 10.1747C68.0154 7.64807 69.8062 6.5 71.597 6.5ZM74.5802 16.9656V39.0936H68.5207V16.9656H74.5802Z"
        fill="#005AA0"
      />
      <path
        d="M96.1452 31.7043V18.5303C93.5743 17.2892 90.8216 16.509 88.0201 16.509C81.4553 16.3716 77.0935 20.8708 77.0935 27.8479C77.0935 34.8249 80.4934 39.5103 85.6353 39.5103C90.4271 39.5103 91.5308 36.5049 91.5308 36.5049V36.465C92.2977 39.8605 95.6222 39.8073 98.1222 39.0981L98.1666 34.918C96.3758 35.2859 96.1452 33.863 96.1452 31.7043ZM90.2232 32.8524C89.0751 33.8143 87.8827 34.0004 87.0538 34.0004C84.6646 34.0004 83.153 31.8417 83.153 27.8479C83.153 24.2219 84.8951 21.7884 87.3774 21.7884C88.2949 21.7884 89.2568 21.9258 90.2232 22.2494V32.8568V32.8524Z"
        fill="#005AA0"
      />
      <path
        d="M111.434 16.9656V23.1227H119.497V39.0981H125.654V16.9656H111.434Z"
        fill="#005AA0"
      />
      <path
        d="M103.521 16.9656V39.0981H117.737V32.9411H109.674V16.9656H103.521Z"
        fill="#005AA0"
      />
      <path
        d="M117.737 24.8824H111.434V31.1857H117.737V24.8824Z"
        fill="#E20917"
      />
      <path
        d="M79.208 48.6017C82.6788 48.6017 81.9385 55.4325 78.0644 55.4325H76.0829L75.4712 58.3581H73.9641L76.043 48.6017H79.208ZM78.1619 54.0096C80.0857 54.0096 80.6708 50.1044 78.9686 50.0069H77.1822L76.3888 54.0096H78.1574H78.1619Z"
        fill="#8DAAD8"
      />
      <path
        d="M87.0006 53.8146C86.4554 55.9069 84.2257 56.1551 82.8028 55.3882C82.6078 56.6294 83.2638 57.2721 84.2789 57.2721C84.9084 57.2721 85.6043 57.0904 86.2027 56.7712L85.9633 57.9681C85.2807 58.3449 84.6247 58.4823 83.942 58.4823C81.7523 58.4823 81.0563 56.7801 81.6149 54.6081C82.0626 52.8926 83.2993 51.5008 85.0325 51.5008C86.2293 51.5008 87.3862 52.3075 86.9961 53.8146H87.0006ZM84.7842 52.6976C84.1858 52.6976 83.5874 53.101 83.0821 54.3997C84.0307 54.9982 85.4669 54.7189 85.6885 53.7171C85.8702 52.9237 85.2585 52.702 84.7842 52.702V52.6976Z"
        fill="#8DAAD8"
      />
      <path
        d="M92.014 52.7286H90.2144L89.6027 55.7694C89.3943 56.8289 89.6292 57.1657 90.1612 57.1657C90.4537 57.1657 90.8837 57.0815 91.2915 56.9264L91.1098 58.2074C90.6089 58.3892 90.1612 58.5 89.7135 58.5C88.5166 58.5 87.8473 57.5647 88.2507 55.601L88.8491 52.7286L87.803 52.6311L88.0556 51.6426H89.155L89.7401 49.5814H90.9103L90.4803 51.6426H92.28L92.014 52.7286Z"
        fill="#8DAAD8"
      />
      <path
        d="M93.6365 51.6381H95.0461L93.6232 58.3581H92.2136L93.6365 51.6381ZM94.656 50.397C93.5523 50.397 93.7207 48.5973 94.9885 48.5973C96.1188 48.5973 95.813 50.397 94.656 50.397Z"
        fill="#8DAAD8"
      />
      <path
        d="M96.3493 51.6116C97.2403 51.3899 98.2731 51.4875 98.1623 52.6843C98.7341 51.8066 99.4567 51.5008 100.184 51.5008C101.677 51.5008 102.01 52.7685 101.677 54.4441L101.216 56.7579C101.119 57.2588 101.301 57.414 101.859 57.2854L101.638 58.3316C100.521 58.5975 99.4611 58.3714 99.8246 56.5895L100.286 54.413C100.552 53.1586 100.117 52.7242 99.4788 52.7242C98.8405 52.7242 98.1269 53.3536 97.9052 54.4529L97.0807 58.3582H95.6711L96.6596 53.6861C96.8414 52.7951 96.7572 52.569 96.1011 52.6976L96.3538 51.6116H96.3493Z"
        fill="#8DAAD8"
      />
      <path
        d="M103.934 56.3766C103.823 57.0726 104.324 57.3651 104.838 57.3651C105.352 57.3651 105.897 57.1834 106.079 56.3633C106.19 55.7339 105.773 55.5566 105.272 55.4724C103.823 55.2064 103.127 54.5681 103.251 53.5752C103.433 52.0991 104.643 51.4962 105.968 51.4962C107.223 51.4962 108.211 52.1789 107.879 53.5176H106.584C106.708 52.8482 106.252 52.6399 105.746 52.6399C105.188 52.6399 104.59 53.03 104.59 53.7126C104.59 54.147 104.966 54.2844 105.467 54.3686C107.196 54.6612 107.67 55.3704 107.431 56.5274C107.138 57.9636 105.91 58.491 104.669 58.491C103.428 58.491 102.258 58.03 102.551 56.3722H103.929L103.934 56.3766Z"
        fill="#8DAAD8"
      />
      <path
        d="M113.592 56.4343C113.424 57.2588 113.424 57.4228 113.925 57.3696L113.703 58.3581C113.007 58.5399 111.877 58.6507 112.085 57.4228C111.624 58.203 110.76 58.4955 110.175 58.4955C109.005 58.4955 108.264 57.5204 108.712 55.4148L109.519 51.6382H110.942L110.117 55.5256C109.895 56.5984 110.077 57.281 110.8 57.281C111.522 57.281 112.152 56.5585 112.373 55.4813L113.18 51.6337H114.59L113.588 56.4299L113.592 56.4343Z"
        fill="#8DAAD8"
      />
      <path
        d="M119.444 51.5007C119.905 51.5007 120.335 51.6691 120.601 52.0149L119.918 53.2826C119.652 53.0167 119.333 52.8792 118.969 52.8792C118.273 52.8792 117.546 53.4378 117.312 54.5504L116.505 58.3536H115.126L116.128 53.6151C116.265 52.959 116.31 52.7108 115.765 52.7507L115.986 51.7179C116.811 51.4962 117.839 51.6071 117.675 52.569C118.039 51.8287 118.774 51.4962 119.444 51.4962V51.5007Z"
        fill="#8DAAD8"
      />
      <path
        d="M125.574 53.8146C125.029 55.9069 122.799 56.1551 121.376 55.3882C121.181 56.6294 121.837 57.2721 122.852 57.2721C123.482 57.2721 124.178 57.0904 124.776 56.7712L124.537 57.9681C123.854 58.3449 123.198 58.4823 122.516 58.4823C120.326 58.4823 119.63 56.7801 120.188 54.6081C120.636 52.8926 121.873 51.5008 123.606 51.5008C124.803 51.5008 125.96 52.3075 125.57 53.8146H125.574ZM123.358 52.6976C122.759 52.6976 122.161 53.101 121.656 54.3997C122.604 54.9982 124.04 54.7189 124.262 53.7171C124.444 52.9237 123.832 52.702 123.358 52.702V52.6976Z"
        fill="#8DAAD8"
      />
    </g>
    <defs>
      <clipPath id="clip0_3632_6509">
        <rect width="125.654" height={65} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
