export const myAccountConfig = {
  company: 'Agria',
  referenceName: 'an Agria',
  seoTitleSuffix: '| My Account | Agria',
  partnerIdName: 'Agria ID',
  partnerLoginInfo: 'For breeders, vets, rehoming organisations',
  breederLoginInfo: 'For Agria Breeder Club members',
  vetLoginInfo: 'For veterinary practices',
  rehomingLoginInfo: 'For rehoming organisations',
  referAFriendUrl: 'https://clk.omgt1.com/?PID=32478&AID=779',
  petReferenceName: 'Pet',
  enableMarketingMaterials: true,
} as const;
